<template>
  <c-modal
    :is-open="isOpen"
    :close-on-overlay-click="true"
    is-centered
    size="2xl"
    :on-close="handleClose"
  >
    <c-modal-content
      ref="content"
      class="modalss"
      data-testid="modal-success-change-password"
    >
      <c-modal-body>
        <CImage
          src="/images/success-iso.png"
          alt="success"
          pos="relative"
          z-index="1"
          mx="auto"
          mb="30px"
        />
        <CHeading
          as="h1"
          color="primary.400"
          font-size="28px"
          font-weight="600"
          margin-bottom="30px"
          font-family="Roboto"
        >
          Cek Email!
        </CHeading>
        <CText
          color="darkGray.900"
          font-size="16px"
          line-hieght="27px"
          font-family="Roboto"
          margin-bottom="30px"
        >
          Selamat! link telah dikirim ke email, silahkan cek email Anda untuk melakukan perubahan password
        </CText>
        <CText
          color="darkGray.900"
          font-size="16px"
          line-hieght="27px"
          font-family="Roboto"
        >
          Saya tidak mendapatkan email <span
            v-show="countDown <= 0"
            class="link"
            @click="handleResend"
          >Kirim ulang</span> <span
            v-show="countDown > 0"
            class="link-disabled"
          >{{ formatStopwatch(countDown * 1000) }}</span>
        </CText>
        <d-button
          style="margin-top: 40px"
          @click="handleClose"
        >
          Kembali
        </d-button>
      </c-modal-body>
    </c-modal-content>
    <c-modal-overlay />
  </c-modal>
</template>

<script>
import DButton from '@/components/elements/d-button.vue'
import iconHome from '@/assets/ic-home.svg'
import { formatStopwatch } from '@/utils/format-stopwatch'
import { mapActions } from 'vuex'

const COUNTDOWN = 30 // seconds

export default {
  components: {
    DButton,
  },
  props: {
    isOpen: {
      type: Boolean,
    },
    email: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      countDown: COUNTDOWN,
      iconHome,
    }
  },
  watch: {
    isOpen() {
      if (this.isOpen) {
        this.countDown = COUNTDOWN
        this.countDownTimer()
      }
    },
  },
  methods: {
    ...mapActions({
      resendVerification: 'auth/forgotPassword',
    }),
    formatStopwatch,
    handleClose() {
      this.$emit('close')
    },
    async handleResend() {
      this.countDown = COUNTDOWN
      this.countDownTimer()
      this.resendVerification({ email: this.email })
        .then(() => {
          this.$toast({
            status: 'success',
            title: 'Success',
            description: 'Sukses mengirimkan email verifikasi',
            duration: 3000,
          })
        })
        .catch(async(err) => {
          this.$toast({
            status: 'error',
            title: 'Error',
            description: err?.message,
            duration: 3000,
          })
        })
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    },
  },
}
</script>
<style scoped>
.modalss::v-deep section {
  border-radius: 16px;
  margin: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px;
}

.link {
  display: block;
  color: #008C81;
  cursor: pointer;
}
.link-disabled {
  display: block;
  color: #a2a2a2;
}
.link:hover {
  text-decoration: underline
}

@media only screen and (min-width: 640px) {
  .link, .link-disabled {
    display: inline-block;
    margin-left: 16px;
  }
}
</style>