var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-modal', {
    attrs: {
      "is-open": _vm.isOpen,
      "close-on-overlay-click": true,
      "is-centered": "",
      "size": "2xl",
      "on-close": _vm.handleClose
    }
  }, [_c('c-modal-content', {
    ref: "content",
    staticClass: "modalss",
    attrs: {
      "data-testid": "modal-error-email-not-found"
    }
  }, [_c('c-modal-body', [_c('CImage', {
    attrs: {
      "src": require('@/assets/images/illustration-2-raw.jpeg'),
      "alt": "success",
      "pos": "relative",
      "z-index": "1",
      "mx": "auto",
      "mb": "30px",
      "h": "200px"
    }
  }), _c('CHeading', {
    attrs: {
      "as": "h1",
      "color": "primary.400",
      "font-size": "28px",
      "font-weight": "600",
      "margin-bottom": "30px",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Email Tidak Terdaftar! ")]), _c('CText', {
    attrs: {
      "color": "darkGray.900",
      "font-size": "16px",
      "line-hieght": "27px",
      "font-family": "Roboto",
      "margin-bottom": "30px"
    }
  }, [_vm._v(" Mohon maaf, email kamu tidak dapat ditemukan. Mohon untuk mengecek ulang email anda. ")]), _c('d-button', {
    staticStyle: {
      "margin-top": "40px"
    },
    on: {
      "click": _vm.handleClose
    }
  }, [_vm._v(" Kembali ")])], 1)], 1), _c('c-modal-overlay')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }