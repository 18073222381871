var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-col justify-center items-center py-6 md:py-0 md:px-6 md:flex-row md:gap-16"
  }, [_c('header', {
    staticClass: "flex flex-col items-center gap-4 w-full md:w-[400px]"
  }, [_c('img', {
    staticClass: "header__icon-dietela",
    attrs: {
      "src": "https://ik.imagekit.io/dietela/pwa_webp/new_landing_page/logo-color@3x.webp?updatedAt=1689904664797",
      "alt": "icon logo dietela"
    }
  }), _vm.hasQueryToken ? _c('img', {
    staticClass: "header__icon-login",
    attrs: {
      "src": require('@/assets/images/illustration-5-raw.jpeg'),
      "alt": "icon login dietela"
    }
  }) : _c('img', {
    staticClass: "header__icon-login",
    attrs: {
      "src": require('@/assets/images/illustration-3-raw.jpeg'),
      "alt": "icon login dietela"
    }
  })]), _c('main', {
    staticClass: "flex flex-col items-center justify-center w-full px-5 md:px-0 md:py-16 md:h-screen md:overflow-auto md:w-[400px] md:scrollbar-hide"
  }, [_c('div', {
    staticClass: "bg-dgreen w-full text-center text-white p-2.5 rounded-t-lg mt-4 text-sm font-medium md:mt-0"
  }, [_vm.hasQueryToken ? _c('h1', [_vm._v(" Ubah Password ")]) : _c('h1', [_vm._v(" Lupa Password ")])]), _c('div', {
    staticClass: "flex flex-col w-full"
  }, [_vm.hasQueryToken ? _c('FormulateForm', {
    staticClass: "tab-form",
    attrs: {
      "debounce": 50
    },
    on: {
      "submit": _vm.handleSubmitChangePassword
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var isValid = _ref.isValid,
            isLoading = _ref.isLoading;
        return [_c('FormulateInput', {
          attrs: {
            "name": "password",
            "label": "Password baru",
            "icon-prefix": _vm.iconPassword,
            "icon-prefix-style": {
              marginTop: '-2px'
            },
            "placeholder": "Masukkan kata sandi",
            "validation": "required|min:8,length|hasAlphaNum|hasSpecialCharacter|hasUpperCase|hasLowerCase",
            "type": "password"
          }
        }), _c('FormulateInput', {
          attrs: {
            "name": "password_confirm",
            "label": "Ulangi password",
            "icon-prefix": _vm.iconPassword,
            "icon-prefix-style": {
              marginTop: '-2px'
            },
            "placeholder": "Masukkan kata sandi",
            "validation": "required|confirm",
            "validation-name": "Password",
            "type": "password"
          }
        }), _c('div', {
          staticClass: "tab-form-action"
        }, [_c('d-button', {
          attrs: {
            "type": "submit",
            "disabled": !isValid || isLoading
          }
        }, [_vm._v(" Simpan ")])], 1)];
      }
    }], null, false, 2486936503),
    model: {
      value: _vm.formValuesChangePassword,
      callback: function callback($$v) {
        _vm.formValuesChangePassword = $$v;
      },
      expression: "formValuesChangePassword"
    }
  }) : _c('FormulateForm', {
    staticClass: "tab-form",
    attrs: {
      "debounce": 50
    },
    on: {
      "submit": _vm.handleSubmit
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var isValid = _ref2.isValid,
            isLoading = _ref2.isLoading;
        return [_c('FormulateInput', {
          attrs: {
            "name": "email",
            "label": "Email",
            "icon-prefix": _vm.iconEmail,
            "icon-prefix-style": {
              width: '20px'
            },
            "placeholder": "Masukkan email",
            "validation": "required|email",
            "type": "text",
            "data-testid": "input-email"
          }
        }), _c('div', {
          staticClass: "tab-form-action"
        }, [_c('d-button', {
          attrs: {
            "type": "submit",
            "disabled": !isValid || isLoading,
            "data-testid": "button-submit"
          }
        }, [_vm._v(" Kirim ")])], 1)];
      }
    }]),
    model: {
      value: _vm.formValues,
      callback: function callback($$v) {
        _vm.formValues = $$v;
      },
      expression: "formValues"
    }
  })], 1)]), _c('ModalVerification', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isModalVerificationOpen,
      expression: "isModalVerificationOpen"
    }],
    attrs: {
      "is-open": _vm.isModalVerificationOpen,
      "email": _vm.formValues.email
    },
    on: {
      "close": _vm.handleCloseModalVerification
    }
  }), _c('ModalErrorEmailNotFound', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isModalErrorEmailNotFound,
      expression: "isModalErrorEmailNotFound"
    }],
    attrs: {
      "is-open": _vm.isModalErrorEmailNotFound
    },
    on: {
      "close": _vm.handleCloseModalErrorEmailNotFound
    }
  }), _c('ModalSuccessChangePassword', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isModalSuccessChangePassword,
      expression: "isModalSuccessChangePassword"
    }],
    attrs: {
      "is-open": _vm.isModalSuccessChangePassword
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }