<template>
  <c-modal
    :is-open="isOpen"
    :close-on-overlay-click="true"
    is-centered
    size="2xl"
    :on-close="handleClose"
  >
    <c-modal-content
      ref="content"
      class="modalss"
      data-testid="modal-error-email-not-found"
    >
      <c-modal-body>
        <CImage
          :src="require('@/assets/images/illustration-2-raw.jpeg')"
          alt="success"
          pos="relative"
          z-index="1"
          mx="auto"
          mb="30px"
          h="200px"
        />
        <CHeading
          as="h1"
          color="primary.400"
          font-size="28px"
          font-weight="600"
          margin-bottom="30px"
          font-family="Roboto"
        >
          Email Tidak Terdaftar!
        </CHeading>
        <CText
          color="darkGray.900"
          font-size="16px"
          line-hieght="27px"
          font-family="Roboto"
          margin-bottom="30px"
        >
          Mohon maaf, email kamu tidak dapat ditemukan. Mohon untuk mengecek ulang email anda.
        </CText>
        <d-button
          style="margin-top: 40px"
          @click="handleClose"
        >
          Kembali
        </d-button>
      </c-modal-body>
    </c-modal-content>
    <c-modal-overlay />
  </c-modal>
</template>

<script>
import DButton from '@/components/elements/d-button.vue'

export default {
  components: {
    DButton,
  },
  props: {
    isOpen: {
      type: Boolean,
    },
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
  },
}
</script>
<style scoped>
.modalss::v-deep section {
  border-radius: 16px;
  margin: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px;
}

.link {
  display: block;
  color: #008C81;
  cursor: pointer;
}
.link-disabled {
  display: block;
  color: #a2a2a2;
}
.link:hover {
  text-decoration: underline
}

@media only screen and (min-width: 640px) {
  .link, .link-disabled {
    display: inline-block;
    margin-left: 16px;
  }
}
</style>