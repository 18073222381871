var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-modal', {
    attrs: {
      "is-open": _vm.isOpen,
      "close-on-overlay-click": false,
      "is-centered": "",
      "size": "2xl"
    }
  }, [_c('c-modal-content', {
    ref: "content",
    staticClass: "modalss"
  }, [_c('c-modal-body', [_c('CImage', {
    attrs: {
      "src": "https://ik.imagekit.io/dietela/pwa_webp/popup/popup_changepassword_success.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1677042871711",
      "alt": "success",
      "pos": "relative",
      "z-index": "1",
      "mx": "auto",
      "mb": "30px",
      "h": "170px"
    }
  }), _c('CHeading', {
    attrs: {
      "as": "h1",
      "color": "primary.400",
      "font-size": "28px",
      "font-weight": "600",
      "margin-bottom": "30px",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Password berhasil diubah! ")]), _c('CText', {
    attrs: {
      "color": "darkGray.900",
      "font-size": "16px",
      "line-hieght": "27px",
      "font-family": "Roboto",
      "margin-bottom": "30px"
    }
  }, [_vm._v(" Selamat! Perubahan Anda berhasil! ")]), _c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('d-button', {
    staticStyle: {
      "margin-top": "40px"
    },
    attrs: {
      "icon-end": _vm.iconHome
    }
  }, [_vm._v(" Homepage ")])], 1)], 1)], 1), _c('c-modal-overlay')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }