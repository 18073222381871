import { render, staticRenderFns } from "./modal-success-change-password.vue?vue&type=template&id=3ea274b0&scoped=true&"
import script from "./modal-success-change-password.vue?vue&type=script&lang=js&"
export * from "./modal-success-change-password.vue?vue&type=script&lang=js&"
import style0 from "./modal-success-change-password.vue?vue&type=style&index=0&id=3ea274b0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ea274b0",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CImage: require('@chakra-ui/vue').CImage, CHeading: require('@chakra-ui/vue').CHeading, CText: require('@chakra-ui/vue').CText, CModalBody: require('@chakra-ui/vue').CModalBody, CModalContent: require('@chakra-ui/vue').CModalContent, CModalOverlay: require('@chakra-ui/vue').CModalOverlay, CModal: require('@chakra-ui/vue').CModal})
