<template>
  <div class="flex flex-col justify-center items-center py-6 md:py-0 md:px-6 md:flex-row md:gap-16">
    <header class="flex flex-col items-center gap-4 w-full md:w-[400px]">
      <img
        class="header__icon-dietela"
        src="https://ik.imagekit.io/dietela/pwa_webp/new_landing_page/logo-color@3x.webp?updatedAt=1689904664797"
        alt="icon logo dietela"
      >
      <img
        v-if="hasQueryToken"
        class="header__icon-login"
        :src="require('@/assets/images/illustration-5-raw.jpeg')"
        alt="icon login dietela"
      >
      <img
        v-else
        class="header__icon-login"
        :src="require('@/assets/images/illustration-3-raw.jpeg')"
        alt="icon login dietela"
      >
    </header>
    <main class="flex flex-col items-center justify-center w-full px-5 md:px-0 md:py-16 md:h-screen md:overflow-auto md:w-[400px] md:scrollbar-hide">
      <div class="bg-dgreen w-full text-center text-white p-2.5 rounded-t-lg mt-4 text-sm font-medium md:mt-0">
        <!-- Change Password -->
        <h1 v-if="hasQueryToken">
          Ubah Password
        </h1>
        <!-- Submit Email -->
        <h1 v-else>
          Lupa Password
        </h1>
      </div>
      <div class="flex flex-col w-full">
        <!-- Change Password -->
        <FormulateForm
          v-if="hasQueryToken"
          v-slot="{ isValid, isLoading }"
          v-model="formValuesChangePassword"
          class="tab-form"
          :debounce="50"
          @submit="handleSubmitChangePassword"
        >
          <FormulateInput
            name="password"
            label="Password baru"
            :icon-prefix="iconPassword"
            :icon-prefix-style="{
              marginTop: '-2px',
            }"
            placeholder="Masukkan kata sandi"
            validation="required|min:8,length|hasAlphaNum|hasSpecialCharacter|hasUpperCase|hasLowerCase"
            type="password"
          />
          <FormulateInput
            name="password_confirm"
            label="Ulangi password"
            :icon-prefix="iconPassword"
            :icon-prefix-style="{
              marginTop: '-2px',
            }"
            placeholder="Masukkan kata sandi"
            validation="required|confirm"
            validation-name="Password"
            type="password"
          />
          <div class="tab-form-action">
            <d-button
              type="submit"
              :disabled="!isValid || isLoading"
            >
              Simpan
            </d-button>
          </div>
        </FormulateForm>
        <!-- Submit Email -->
        <FormulateForm
          v-else
          v-slot="{ isValid, isLoading }"
          v-model="formValues"
          class="tab-form"
          :debounce="50"
          @submit="handleSubmit"
        >
          <FormulateInput
            name="email"
            label="Email"
            :icon-prefix="iconEmail"
            :icon-prefix-style="{
              width: '20px'
            }"
            placeholder="Masukkan email"
            validation="required|email"
            type="text"
            data-testid="input-email"
          />
          <div class="tab-form-action">
            <d-button
              type="submit"
              :disabled="!isValid || isLoading"
              data-testid="button-submit"
            >
              Kirim
            </d-button>
          </div>
        </FormulateForm>
      </div>
    </main>
    <ModalVerification
      v-show="isModalVerificationOpen"
      :is-open="isModalVerificationOpen"
      :email="formValues.email"
      @close="handleCloseModalVerification"
    />
    <ModalErrorEmailNotFound
      v-show="isModalErrorEmailNotFound"
      :is-open="isModalErrorEmailNotFound"
      @close="handleCloseModalErrorEmailNotFound"
    />
    <ModalSuccessChangePassword
      v-show="isModalSuccessChangePassword"
      :is-open="isModalSuccessChangePassword"
    />
  </div>
</template>

<script >
import { computed, ref } from '@vue/composition-api';
import { useRoute } from 'vue2-helpers/vue-router';
import iconEmail from '@/assets/ic-mail.svg';
import iconPassword from '@/assets/ic-padlock.svg';
import DButton from '@/components/elements/d-button.vue';
import ModalVerification from '@/components/widgets/modal-send-verfication-change-password.vue';
import ModalErrorEmailNotFound from '@/components/widgets/modal-error-email-not-found.vue';
import ModalSuccessChangePassword from '@/components/widgets/modal-success-change-password.vue';
import { useActions } from '@/stores';
import { useToast } from '@chakra-ui/vue';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const toast = useToast();
  const route = useRoute();
  const {
    forgotPassword,
    resetPassword
  } = useActions('auth', ['forgotPassword', 'resetPassword']);
  const isModalVerificationOpen = ref(false);
  const isModalErrorEmailNotFound = ref(false);
  const isModalSuccessChangePassword = ref(false);
  const formValues = ref({
    email: ''
  });
  const formValuesChangePassword = ref({
    password: '',
    password_confirm: ''
  });
  const hasQueryToken = computed(() => !!route.query.token);
  const token = computed(() => route.query.token);

  const handleCloseModalErrorEmailNotFound = () => {
    isModalErrorEmailNotFound.value = false;
  };

  const handleCloseModalVerification = () => {
    isModalVerificationOpen.value = false;
  };

  const handleSubmitChangePassword = async _formValuesChangePassword => {
    try {
      await resetPassword({
        token: token.value,
        password: _formValuesChangePassword.password
      });
      isModalSuccessChangePassword.value = true;
      toast({
        status: 'success',
        title: 'Success',
        description: 'Sukses merubah kata sandi',
        duration: 3000
      });
    } catch (e) {
      toast({
        status: 'error',
        title: 'Error',
        description: e?.data?.message || 'Error!',
        duration: 3000
      });
    }
  };

  const handleSubmit = async _formValues => {
    try {
      await forgotPassword({
        email: _formValues.email
      });
      isModalVerificationOpen.value = true;
      toast({
        status: 'success',
        title: 'Success',
        description: 'Sukses mengirimkan email verifikasi',
        duration: 3000
      });
    } catch (e) {
      if (e?.data?.message === 'user not found') {
        isModalErrorEmailNotFound.value = true;
      }

      toast({
        status: 'error',
        title: 'Error',
        description: e?.data?.message || 'Error!',
        duration: 3000
      });
    }
  };

  return {
    iconEmail,
    iconPassword,
    isModalVerificationOpen,
    isModalErrorEmailNotFound,
    isModalSuccessChangePassword,
    formValues,
    formValuesChangePassword,
    hasQueryToken,
    handleCloseModalErrorEmailNotFound,
    handleCloseModalVerification,
    handleSubmitChangePassword,
    handleSubmit
  };
};

__sfc_main.components = Object.assign({
  DButton,
  ModalVerification,
  ModalErrorEmailNotFound,
  ModalSuccessChangePassword
}, __sfc_main.components);
export default __sfc_main;
</script>

<style scoped>
.header__icon-dietela{
  width: 110px;
}
.header__icon-login{
  width: 60%;
}
.tab-form {
  padding: 25px;
  border: 2px solid #008C81;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.tab-form-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.tab-form-action__link > a {
  font-size: 12px;
  font-weight: 500;
  color: #008C81;
  cursor: pointer;
}

.formulate-input::v-deep .formulate-input-errors  {
  color: red;
  margin-left: 20px;
  font-size: 14px;
  margin-top: 6px;
}

@media (min-width: 640px) {
  .tab-form {
    height: 100%;
    overflow: auto;

  }

  .tab-form::-webkit-scrollbar {
    display: none;
  }

  .header__icon-login{
    width: 100%;
    max-width: 450px;
  }

  .header__icon-dietela {
    width: 80px;
  }
}

.checkbox::v-deep div {
  font-size: 12px;
}
</style>
